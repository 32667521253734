<template>
  <!-- <div class="columns no-margin"> -->
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">{{ headerText }} PASIEN</p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field v-if="!isCreate">
            <strong>No RM: {{ this.pasien.no_rm }}</strong>
          </b-field>
          <b-field
            v-else
            label="No RM"
            custom-class="is-small"
            :type="{'is-danger': errorMap.no_rm}"
            :message="errorMap.no_rm"
          >
            <b-input
              ref="firstInput"
              v-model="pasien.no_rm"
              required
              @input="validateInput('no_rm')"
            ></b-input>
          </b-field>
          <b-field
            label="Nama"
            custom-class="is-small"
            :type="{'is-danger': errorMap.nama}"
            :message="errorMap.nama"
          >
            <b-input
              v-model="pasien.nama"
              maxlength="4"
              :has-counter="false"
              required
              @input="validateInput('nama')"
            ></b-input>
          </b-field>
          <b-field
            label="Jenis Kelamin"
            custom-class="is-small"
            :type="{'is-danger': errorMap.gender}"
            :message="errorMap.gender"
          >
            <b-select
              v-model="pasien.gender"
              placeholder="Pilih"
              expanded
              required
              @input="validateInput('gender')"
            >
              <option value="Laki-Laki">Laki-Laki</option>
              <option value="Perempuan">Perempuan</option>
            </b-select>
          </b-field>
          <b-field
            label="Tgl Lahir"
            custom-class="is-small"
            :type="{'is-danger': errorMap.tgl_lahir}"
            :message="errorMap.tgl_lahir"
          >
            <date-picker v-model="pasien.tgl_lahir" @input="validateInput('tgl_lahir')"></date-picker>
          </b-field>

          <b-field label="Catatan" custom-class="is-small notrequired" 
            :type="{'is-danger': errorMap.catatan}"
            :message="errorMap.catatan">
            <b-input v-model="pasien.catatan" type="textarea" @input="validateInput('catatan')"></b-input>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { has } from "lodash";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Pasien from "../models/pasien.js";

export default {
  name: "PasienCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue")
  },
  data() {
    // created dipanggil lebih dulu dari created()
    this.pasienMdl = new Pasien();
    this.objectMdl = this.pasienMdl; // alias yg digunakan di cuMixin
    return this.pasienMdl.getObservables();
  },
  methods: {
    ...mapActions("aktivitas", ["setAktPSPDState"]),
    ...mapActions("perawatan", ["setPerawatan"]),
    setPerawatanPasien(respData) {
      this.$route.params.perawatan.pasien = respData.id;
      this.$route.params.perawatan.edited = true;
      this.cancel();
    },
    saveObject() {
      let saveParams = [this.saveContext];
      if (has(this.$route.params, "perawatan")) {
        saveParams.push(this.setPerawatanPasien);
      } else {
        saveParams.push(() => {
          this.cancel();
        });
      }
      this.pasienMdl.save(...saveParams);
    },
    cancel() {
      if (has(this.$route.params, "perawatan")) {
        let perawatanPasien = this.$route.params.perawatan;
        let aktPSPD = this.$route.params.aktPSPD;
        this.setPerawatan(perawatanPasien);
        this.setAktPSPDState(aktPSPD);
      }
      this.objectMdl.reset(); // apakah diperlukan ???
      this.$router.go(-1);
    }
  },
  watch: {
    pasien: {
      // pasien berubah, update edited
      handler(newValue, oldValue) {
        if (!this.pasienMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  },
  created() {
    if (this.isCreate && has(this.$route.params, "perawatan")) {
      this.pasien.no_rm = this.$route.params.perawatan.pasienInput;
      if (this.pasien.no_rm) {
        this.pasienMdl.validate("no_rm");
      }
    }
  }
};
</script>
